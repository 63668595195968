import * as schema from '../../../data/projectSchema.js';

import * as ACTION from '../actionTypes';
import * as constants from '../../../constants/app.js';
import * as utils from '../../../utils/generalUtilities.js';
import { yearsToMonths } from 'date-fns';

export function setWorkflows(workflows) {
  return {
    type: ACTION.UPDATE_WORKFLOWS,
    workflows: workflows,
  };
}

export function createCoreSmartTemplates(userEmail, setId = constants.CORE_SMART_TEMPLATES_ID) {
  const baseWFProject = utils.createNewTemplate('common components', userEmail, {
    workflowSetId: setId,
    library: {
      components: {
        tasks: [utils.createNewTask('Common Task', null, undefined, true, false)],
        workPackages: [utils.createNewWorkPackage('Common Task Group', null, undefined, true, false)],
        deliverables: [],
      },
      level: 0, // 0 ids root WF
      visible: false, // visible when selecting items
      locked: false,
      default: false, // default workflow to use
      order: 0,
    },
  });
  baseWFProject.workflowSetId = setId;
  baseWFProject.workflowUUID = null;
  const templates = [baseWFProject];

  const firstSmartTemplate = utils.createNewTemplate('First Smart Workflow', userEmail, {
    workflowSetId: setId,
    library: {
      components: {
        tasks: [utils.createNewTask('Example Task', null, undefined, true, false)],
        workPackages: [utils.createNewWorkPackage('Example Task Group', null, undefined, true, false)],
        deliverables: [utils.createNewDeliverable('Example Deliverable', null, undefined, true, false)],
      },
      level: 1, // 0
      visible: true, // visible when selecting items
      locked: false,
      default: true, // default workflow to use
      order: 1,
    },
  });
  firstSmartTemplate.workflowSetId = setId;
  firstSmartTemplate.workflowUUID = baseWFProject.uuid;
  templates.push(firstSmartTemplate);

  return templates;
}

export function getUserTemplates(firebase, field, comparison, value) {
  return new Promise((resolve, reject) => {
    let procError = false;
    return firebase.db
      .collection('workflows') // old name for templates
      .where('deletedDate', '==', null)
      .where(field, comparison, value)
      .get()
      .then(querySnapshot => {
        procError = true;
        const templates = [];
        querySnapshot.forEach(templateDoc => {
          const templateData = templateDoc.data();
          if (!templateData.deletedDate) {
            // const updatedTemplateData = schema.migrateTemplateSchema(templateData);
            templateData.id = templateDoc.id;
            templates.push(templateData);
          }
        });
        if (templates.length >= 0) {
          resolve(templates);
        } else {
          resolve(null);
        }
      })
      .catch(function(err) {
        if (procError) {
          reject(`Processing error during getUserTemplates: {err}`);
        } else {
          reject(`Template with id {value} doesn't yet exist: {err}`);
        }
      });
  });
}

export const filterTemplatesByGroup = (groups, templates, userEmail) => {
  const filteredTemplates = {};
  const existingTemplateIds = {};
  const existingSetIds = [];
  const userGroupTags = {}; // create lookup table for group.id and tag values
  const groupLookup = {};
  groups.forEach(group => {
    groupLookup[group.id] = group;
    group.numVisibleTemplates = 0;
    const splitTags = group.tags.split(/[\s,;]+/);
    splitTags.forEach(tag => {
      tag = tag.trim();
      if (tag.length > 0) {
        if (!userGroupTags[group.id]) userGroupTags[group.id] = {};
        userGroupTags[group.id][tag] = group;
      }
    });
  });
  // filter and separate the templates by setId
  templates.forEach(template => {
    if (!template.deletedDate) {
      if (!template.accessRules) template.accessRules = { emailEquals: [], tagEquals: [] };

      let access =
        template.workflowSetId === constants.PUBLIC_GROUP_ID ||
        template.workflowSetId === constants.CORE_SMART_TEMPLATES_ID;
      if (!access) {
        // private template
        const curGroup = groupLookup[template.workflowSetId];
        if (template.library.level === 0) {
          access = true;
        } else {
          if (curGroup.isAdmin) {
            curGroup.numVisibleTemplates++;
            access = true;
          } else {
            // non public template, check for access using templates accessRules
            const userEmailInAccessRules = template.accessRules.emailEquals.indexOf(userEmail) >= 0;
            let userGroupTagInAccessRules = false;
            template.accessRules.tagEquals.forEach(tempTag => {
              if (userGroupTags[template.workflowSetId] && userGroupTags[template.workflowSetId][tempTag]) {
                userGroupTagInAccessRules = true;
                curGroup.numVisibleTemplates++;
              }
            });
            access = userEmailInAccessRules || userGroupTagInAccessRules;
          }
        }
      }
      if (access) {
        if (!filteredTemplates[template.workflowSetId]) {
          filteredTemplates[template.workflowSetId] = [];
        }
        filteredTemplates[template.workflowSetId].push(template);
        existingTemplateIds[template.uuid] = true;
        if (existingSetIds.indexOf(template.workflowSetId) < 0) existingSetIds.push(template.workflowSetId);
      }
    }
  });
  Object.keys(filteredTemplates).forEach(setId => {
    // remove empty private sets (only have level zero)
    if (setId !== constants.PUBLIC_GROUP_ID && filteredTemplates[setId].length === 1) {
      delete existingTemplateIds[filteredTemplates[setId][0].uuid];
      existingSetIds.splice(existingSetIds.indexOf(setId), 1);
      delete filteredTemplates[setId];
    }
  });
  return { filteredTemplates, existingTemplateIds, existingSetIds };
};
