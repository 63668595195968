export function getUserProjects(firebase, field, comparison, value) {
  return new Promise((resolve, reject) => {
    return firebase.db
      .collection('projects')
      .where('deletedDate', '==', null)
      .where(field, comparison, value)
      .get()
      .then(querySnapshot => {
        let projects = [];
        querySnapshot.forEach(doc => {
          let project = doc.data();
          project.id = doc.id;
          if (field === 'template' || !project.template) {
            // schema.migrateProjectSchema(project);
            project.id = doc.id;
            projects.push(project);
          }
        });
        resolve(projects);
      })
      .catch(function(err) {
        reject(`Error getting ${field} projects: ${err}`);
      });
  });
}

export function addNewProject(firebase, newProject) {
  return new Promise((resolve, reject) => {
    // get the users projects
    return firebase.db
      .collection('projects')
      .add(newProject)
      .then(docRef => {
        newProject.id = docRef.id;
        resolve(newProject);
      })
      .catch(function(err) {
        reject(`Error addProject: ${err}`);
      });
  });
}

export function orderProjects(projects) {
  // The sort order is
  // projects vs templates
  // project name alphabetically
  projects.sort((p1, p2) =>
    p1.name.toLowerCase() < p2.name.toLowerCase()
      ? -1
      : p1.name.toLowerCase() === p2.name.toLowerCase()
      ? 0
      : 1,
  );
  // projects.forEach(proj => {
  //   console.log(proj.name);
  // });
}

export const falseProjectData = [
  {
    name: 'Website Update',
    dueText: null,
    alert: null,
    deliverables: [
      {
        name: 'Home Page',
        dueText: null,
        alert: null,
        workPackages: [
          {
            name: 'Replace the old imagees',
            dueText: '3 days to go',
            alert: 'soon',
            tasks: [
              {
                name: 'Identify potential replacement images',
                dueText: '13 days overdue',
                alert: 'overdue',
              },
            ],
          },
        ],
      },
    ],
  },
];
