import React, { useState, useTheme, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  DialogActions,
  Avatar,
  Select,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import * as utils from '../../utils/generalUtilities.js';
import NamePicker from '../NamePicker';
import Person from '@material-ui/icons/Person';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputField } from '../../components/styled_components/InputField';

const modalStyles = makeStyles(theme => ({
  avatar: {
    height: '25px',
    width: '25px',
    margin: '0 10px 0 0',
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  flexContainer: {
    display: 'flex',
  },
  longInput: {
    flex: 1,
    marginRight: '10px',
  },
  tableContainer: {
    marginBottom: '10px',
  },
  selected: {
    background: theme.palette.primary.dark,
  },
  dialogActions: {
    margin: '10px 0',
  },

  bottomContainer: {
    flex: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    display: 'flex',
    height: '75px',
    margingBottom: '20px',
    // backgroundColor: 'red',
  },
  namePickerContainer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    // margin: '0px 0',
    display: 'flex',
    minWidth: '400px',
    marginLeft: '20px',
    // backgroundColor: 'red',
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    display: 'flex',
  },
  dialogButtons: {
    backgroundColor: theme.palette.secondary.main,
    textTransform: 'none',
  },
}));
const NEW_MEMBER = 'Add new email';
const NO_MEMBER = 'Close';
const TEAM = 'sharedWith';
export const TeamModal = ({
  open,
  toggle,
  viewState,
  team,
  projects,
  user,
  firebase,
  setUserColleguePref,
  saveCurrentProject,
}) => {
  const [selectedRow, setSelected] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [adminName, editAdminName] = useState(null);

  let triggerSubmitForm = null;
  const classes = modalStyles();

  const [teamMember, setTeamMember] = useState({ email: '', useMenu: true, isValidEmail: false });

  const toggleSelected = (rowId, member) => {
    if (rowId === selectedRow) {
      setSelected(null);
      setSelectedMember(null);
    } else {
      setSelected(rowId);
      setSelectedMember(member);
    }
  };

  const validateEmail = values => {
    const errors = {};
    if (!values.member) {
      errors.member = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.member)) {
      errors.member = 'Invalid email address';
    } else {
      const memberInd = team.indexOf(values.member);
      if (memberInd >= 0) {
        errors.member = 'That member email is already on the team list';
      } else {
        const curProject = projects[viewState.currentProjectIndex];
        if (
          values.member === curProject.creator ||
          (curProject.sponsor && curProject.sponsor !== '' && values.member === curProject.sponsor) ||
          (curProject.projectManager &&
            curProject.projectManager !== '' &&
            values.member === curProject.projectManager)
        ) {
          errors.member = 'That member email is already on the team list';
        }
      }
    }
    return errors;
  };
  const curProject = projects[viewState.currentProjectIndex];

  const addMember = member => {
    const trimmedMember = member.trim();
    team.push(trimmedMember); // updates local values
    if (curProject.sharedWith !== team) {
      curProject.sharedWith.push(trimmedMember);
      projects[viewState.currentProjectIndex] = Object.assign({}, curProject); // copy project to reset immutable tests
    }
    const newColorAssignment = {
      email: trimmedMember,
      color: utils.randColor(),
    };
    setUserColleguePref(newColorAssignment);
    saveCurrentProject();
  };
  const swapAdmin = () => {
    if (selectedMember != null) {
      if (
        window.confirm(
          'Please confirm you want to switch the administrator of the team.  Once you do this you will not be able to undo it, they will have to make further changes',
        )
      ) {
        const memberInd = team.indexOf(selectedMember);
        if (memberInd >= 0) {
          team[memberInd] = curProject.creator;
          // curProject.sharedWith[memberInd]  = curProject.creator;
          curProject.creator = selectedMember;
          projects[viewState.currentProjectIndex] = Object.assign({}, curProject); // copy project to reset immutable tests
          setSelectedMember(null);
          saveCurrentProject();
        }
      }
    }
  };
  const removeMember = () => {
    // TODO would be best if we would confirm this deletion
    if (selectedMember != null) {
      const memberInd = team.indexOf(selectedMember);
      if (memberInd >= 0) {
        team.splice(memberInd, 1); // update local values
        if (curProject.sponsor === selectedMember) curProject.sponsor = '';
        if (curProject.projectManager === selectedMember) curProject.projectManager = '';
        utils.removeAssigned(selectedMember, curProject);
        projects[viewState.currentProjectIndex] = Object.assign({}, curProject); // copy project to reset immutable tests
        saveCurrentProject();
      }
    }
  };

  let adminTitle = 'Admin';
  const ascendSort = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };
  const sortedTeam = team.sort(ascendSort);
  return (
    <Dialog
      open={open}
      onClose={() => {
        // update # shares in google analytics
        const uniqueShares = {};
        projects.forEach(project => {
          project.sharedWith.forEach(share => {
            uniqueShares[share] = share;
          });
        });
        toggle(false);
      }}
      aria-labelledby="edit-team"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="edit-team">Edit Team</DialogTitle>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="team table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Permissions</TableCell>
              <TableCell padding="checkbox"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Fragment>
              <TableRow key={'owner'}>
                <TableCell className={classes.avatarWrapper}>
                  <Avatar className={classes.avatar} variant="circle" sizes="sm"></Avatar>
                  {curProject.creator}
                </TableCell>
                <TableCell>{adminTitle}</TableCell>
                <TableCell></TableCell>
              </TableRow>
              {sortedTeam.map(member => {
                if (member !== curProject.creator) {
                  const labelId = `enhanced-table-checkbox-${member}`;
                  const isItemSelected = labelId === selectedRow;
                  return (
                    <TableRow
                      hover
                      onClick={() => toggleSelected(labelId, member)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={member}
                      selected={isItemSelected}
                    >
                      <TableCell className={classes.avatarWrapper}>
                        <Avatar className={classes.avatar} variant="circle" sizes="sm"></Avatar>
                        {member}
                      </TableCell>
                      <TableCell>Can Edit</TableCell>
                      <TableCell padding="checkbox" className={classes.rightColumn}>
                        <Checkbox
                          checked={isItemSelected}
                          className={classes.rightItems}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </Fragment>
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.bottomContainer}>
        <div className={classes.namePickerContainer}>
          <NamePicker
            field={teamMember}
            fieldLabel={'Add New Team Member'}
            newEmailLabel={`Please add email for your new team member`}
            project={curProject}
            community={user.community}
            newPersonText={NEW_MEMBER}
            removeText={NO_MEMBER}
            showProjectTeam={false}
            onBlur={inputValue => {
              setTeamMember({
                email: inputValue,
                useMenu: false,
                isValidEmail: utils.isValidEmail(inputValue),
              });
            }}
            cancelButtonClick={() => {
              setTeamMember({ email: '', useMenu: true, isValidEmail: false });
            }}
            addButtonClick={() => {
              if (teamMember.isValidEmail) {
                addMember(teamMember.email);
                setTeamMember({ email: '', useMenu: true, isValidEmail: false });
              }
            }}
            onMenuChange={e => {
              if (e.target.value === NO_MEMBER) {
                setTeamMember({ email: '', useMenu: true, isValidEmail: false });
                // handleUpdate('', TEAM);
              } else {
                if (e.target.value === NEW_MEMBER) {
                  setTeamMember({ email: '', useMenu: false, isValidEmail: false });
                  // handleUpdate('', TEAM);
                } else {
                  if (e.target.value !== teamMember.email) {
                    addMember(e.target.value);
                    setTeamMember({ email: '', useMenu: true, isValidEmail: false });
                  }
                }
              }
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
              autocomplete: 'off',
            }}
          />
        </div>
        {selectedMember && (
          <div className={classes.buttonRow}>
            <DialogActions className={classes.dialogActions}>
              <Button variant="outlined" className={classes.dialogButtons} onClick={removeMember}>
                Remove Member
              </Button>
            </DialogActions>
            {user.email === curProject.creator && (
              <DialogActions className={classes.dialogActions}>
                <Button variant="outlined" className={classes.dialogButtons} onClick={swapAdmin}>
                  Make Admin
                </Button>
              </DialogActions>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};
